export const generateFormData = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
        if (values[key]) {
            formData.append(key, values[key]);
        }
    });

    return formData;
};

export const urlParamToObject = (location) =>{
    if(location.search.length == 0)
        return {type:'none'};
    var search = location.search.substring(1);
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
}

export const removeSpacesFromId = (id) => {
    let newString = '';
    id.split(' ').forEach((item, i) => {
      if (i === 0) {
        newString = newString + item;
      } else {
        newString = newString + '-' + item;
      }
    });
    return newString;
  };