import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import {
    loginUrl,
    registerUrl,
    forgotPasswordUrl,
    resetPasswordUrl,
    userMe,
    TOKEN,
    THEME,
    storeData,
    originDomain,
    cookieOptions
} from '../config/endPoints';
import { generateFormData } from '../utils/keHelper';
import Cookies from 'js-cookie';

class AuthService {
  token$ = new BehaviorSubject('');
  user$ = new BehaviorSubject(null);
  error$ = new BehaviorSubject(null);

  isShowLogin$ = new BehaviorSubject(false);
  isShowSignup$ = new BehaviorSubject(false);
  isShowNewPassword$ = new BehaviorSubject(false);
  isShowResetPassword$ = new BehaviorSubject(false);
  resetPasswordCode$ = new BehaviorSubject('');

  constructor() {
    if (typeof window !== 'undefined') {
      this.token$.next(Cookies.get(TOKEN));
      //console.log("AuthService Constructor");
      if (this.token$.getValue()!=null && this.user$.getValue() === null) {
  
        this.loadUserData();
      }
    }

  }

  loadUserData = () => {
    axios({
      method: 'get',
      url: userMe,
      headers: { 'Authorization': 'Bearer ' + Cookies.get(TOKEN) },
    }).then(({ data }) => {
     console.log(data, 'login data')
    }).
    catch(error=>{
        console.log("userMe error",error);
      if(error.response?.status == 401)
      {
        this.logout();
      }
    });
  };

  login = (values,resultProc) => {
    axios.post(loginUrl, {
        identifier: values.email,
        password:values.password
    }).then(({data}) => {
        console.log("login",data);

        Cookies.set(TOKEN, data.jwt, cookieOptions);
        this.token$.next(data.jwt);
        
        Cookies.set("userId", data.user.id, cookieOptions);
        Cookies.set("userEmail", data.user.email, cookieOptions);
        Cookies.set("userName", data.user.username, cookieOptions);
        this.user$.next(data.user);
        resultProc("success");

        if(localStorage.getItem(THEME) && localStorage.getItem(THEME) === "bp3-dark")
        {
          document.body.classList.toggle("bp3-dark");
        }
      }).catch(error => {
        console.log(error);
        resultProc("failed");
      });
  };
  registration = (values,resultProc) => {
    const formData = generateFormData(values);

    axios.post(registerUrl, formData)
      .then(({data}) => {
        Cookies.set(TOKEN, data.jwt, cookieOptions);
        this.token$.next(data.jwt);
        Cookies.set("userId", data.user.id, cookieOptions);
        Cookies.set("userEmail", data.user.email, cookieOptions);
        Cookies.set("userName", data.user.username, cookieOptions);
        Cookies.set('isNewUser', true, cookieOptions);
        this.user$.next(data.user);
        // this.token$.next(data.data.token);
        // localStorageHelper.setToken(data.data.token);
        resultProc("success")
      })
      .catch(error => {
        console.log(error);
        resultProc("failed")
      });
  };

  logout = () => {
    Cookies.remove(TOKEN);
    Cookies.remove('role');
    Cookies.remove('userId');
    this.token$.next('');
    if(document.body.className.includes("bp3-dark"))
    {
      document.body.classList.toggle("bp3-dark");
      localStorage.setItem(THEME, "bp3-dark");
    }
    else
    {
      localStorage.setItem(THEME, null);
    }

  };

  /*resetPassword = (values) => {
    return axios.put(resetPasswordUrl, values);
  };*/

  forgetPassword = (email,resultProc) =>{
    axios.post(forgotPasswordUrl, {
      email: email,
    }).then(response => {
      console.log("response",response);
      resultProc({success:true,msg:'You received an email'});
    }).catch((error) => {
      console.log("forget password",error);
      resultProc({success:false,msg:"Reset password failed"});
      //console.log('An error occurred:',error.response );
    });
  }

  resetPassword = (values,code,resultProc) =>{
    axios.post(resetPasswordUrl, {
      code: code,
      password: values.password,
      passwordConfirmation: values.confirmPassword,
    }).then(response => {
      resultProc({success:true,msg:"Your user's password has been changed."});
    })
    .catch(error => {
      console.log("change password",error);
      resultProc({success:false,msg:"Change password failed"});
    });
  }
};

export const authService = new AuthService();
