export const STRAPI_URL = "https://strapi.mendelgen.com/";
//export const STRAPI_URL = "http://127.0.0.1:1337/";
export const TOKEN = "token";
export const THEME = "theme_type";
/** Auth Urls*/

export const originDomain = "mendelgen.com";
//export const originDomain = "localhost";

export const cookieOptions = {
  path: "/",
  expires: 30, //days
  sameSite: "strict",
  secure: true,
  domain: originDomain,
};

export const loginUrl = `${STRAPI_URL}auth/local`;

export const registerUrl = `${STRAPI_URL}auth/local/register`;

export const userMe = `${STRAPI_URL}users/me`;

export const storeData = `${STRAPI_URL}data`;

export const resetPasswordUrl = `${STRAPI_URL}auth/reset-password`;

export const forgotPasswordUrl = `${STRAPI_URL}auth/forgot-password`;

export const getSequenceDB = `${STRAPI_URL}data/byUser/`;

export const getPublicData = `${STRAPI_URL}data/public/`;

export const getSequenceData = `${STRAPI_URL}data/`;

// This is the url that allows us to update the feature library associated with an individual user
export const updateFeatureLibrary = `${STRAPI_URL}users-permissions/updateFeatureLibrary`;
export const updatePrimerLibrary = `${STRAPI_URL}users-permissions/updatePrimerLibrary`;
export const updateUserSettings = `${STRAPI_URL}users-permissions/updateUserSettings`;

//export const redirectUrl = "http://localhost:3344/plans";
//export const redirectDashUrl = "http://localhost:3344/Dashboard";


export const redirectUrl = 'https://app.mendelgen.com/plans'
export const redirectDashUrl = "https://app.mendelgen.com/Dashboard";
