import react, { useEffect, useState } from 'react';
import { authService } from '../services/AuthService';

export const logout = () => {
  authService.logout()
};

export const useAuth = () => {
  const [token, setToken] = useState(authService.token$.getValue());
  const [user, setUser] = useState(authService.user$.getValue());

  const [isShowLogin, setIsShowLogin] = useState(authService.isShowLogin$.getValue());
  const [isShowSignup, setIsShowSignup] = useState(authService.isShowSignup$.getValue());
  const [isShowNewPassword, setIsShowNewPassword] = useState(authService.isShowNewPassword$.getValue());
  const [isShowResetPassword, setIsShowResetPassword] = useState(authService.isShowResetPassword$.getValue());
  const [resetPasswordCode, setResetPasswordCode] = useState(authService.resetPasswordCode$.getValue());
  useEffect(() => {
    const tokenSubscriber = authService.token$.subscribe(setToken);
    const userSubscriber = authService.user$.subscribe(setUser);

    const isShowLoginSubscriber = authService.isShowLogin$.subscribe(setIsShowLogin);
    const isShowSignupSubscriber = authService.isShowSignup$.subscribe(setIsShowSignup);
    const isShowNewPasswordSubscriber = authService.isShowNewPassword$.subscribe(setIsShowNewPassword);
    const isShowResetSubscriber = authService.isShowResetPassword$.subscribe(setIsShowResetPassword);
    const resetPasswordCodeSubscriber = authService.resetPasswordCode$.subscribe(setResetPasswordCode);
    return () => {
      tokenSubscriber.unsubscribe();
      userSubscriber.unsubscribe();

      isShowLoginSubscriber.unsubscribe();
      isShowSignupSubscriber.unsubscribe();
      isShowNewPasswordSubscriber.unsubscribe();
      isShowResetSubscriber.unsubscribe();
      resetPasswordCodeSubscriber.unsubscribe();
    }
  }, []);


  const login = (values,resultProc) => {
    authService.login(values,resultProc)
  };


  const registration = (values,resultProc) => {
    authService.registration(values,resultProc)
  };

  

  const loadUserData = () => {
    authService.loadUserData();
  };

  const forgetPassword = (email,resultProc)=>{
    authService.forgetPassword(email,resultProc);
  };

  const resetPassword = (values,code,resultProc)=>{
    authService.resetPassword(values,code,resultProc);
  }

  const setShowLogin = (value) =>{
    authService.isShowLogin$.next(value);
  }

  const setShowSignup = (value) =>{
    authService.isShowSignup$.next(value);
  }

  const setShowNewPassword = (value,code) =>{
    authService.isShowNewPassword$.next(value);
    authService.resetPasswordCode$.next(code);
  }

  const setShowResetPassword = (value) =>{
    authService.isShowResetPassword$.next(value);
  }

  return {
    user,
    token,
    isShowLogin,
    isShowSignup,
    isShowNewPassword,
    isShowResetPassword,
    resetPasswordCode,
    login,
    registration,
    loadUserData,
    forgetPassword,
    resetPassword,
    setShowLogin,
    setShowSignup,
    setShowNewPassword,
    setShowResetPassword
  };
};
