export const validation = (values) => {
  const errors = {};

  /*if (!values.username) {
    errors.username = 'Required';
  } else if (!/^[a-zA-Z0-9]+$/i.test(values.username.trim())) {
    errors.username = 'Must be only letters';
  }*/

  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

