import React,{useState} from 'react';
import { BsX } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik';
import {useAuth} from "../../hook/useAuth"
import { useToasts } from 'react-toast-notifications'
import {validation} from './validate'
import {loginUrl, redirectUrl, originDomain, cookieOptions} from '../../config/endPoints';
import axios from 'axios';
import Cookies from 'js-cookie';
// var createHost = require("cross-domain-storage/host");

// if (typeof window !== "undefined") {
//     // Client-side-only code
//     createHost([
//         {
//           origin: redirectUrl,
//           allowedMethods: ["get", "set", "remove"],
//         },
//       ]);
//   }

const Login = (props) => {

    const {showModal,closeProc,resetPassword} = props;
    const { addToast } = useToasts();
    const { login } = useAuth();
    const [showError, setShowError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");

    const resultProc = (result) =>{
        if(result == "success")
        {
            closeProc();
            addToast('Login success', { appearance: 'success' });
            if (localStorage.getItem("privateVectorId")) {
              const privateVectorId = localStorage.getItem("privateVectorId");
              if (localStorage.getItem("secretKey")) {
                const secretKey = localStorage.getItem("secretKey");
                localStorage.removeItem("secretKey");
                localStorage.removeItem("privateVectorId");
                props.history.push(
                  `/Editor?id=${privateVectorId}&secret=${secretKey}`
                );
                return window.toastr.success("Loading shared vector");
              }
              localStorage.removeItem("privateVectorId");
              props.history.push(`/Editor?id=${privateVectorId}`);
              window.toastr.success("Loading shared vector");
            } else {
              props.history.push("/Dashboard");
            }
           
        }
        else
        {
            setShowError(true);
            setErrorMsg("Login failed")
        }
    }


    const loginFromNext = (values) => {
        console.log(values, 'values')

        axios.post(loginUrl, {
            identifier: values.email,
            password: values.password
        }).then(({data}) => {
            console.log("login",data);
            Cookies.set("token", data.jwt, cookieOptions);
            Cookies.set("userId", data.user.id, cookieOptions);
            Cookies.set("userEmail", data.user.email, cookieOptions);
            Cookies.set("userName", data.user.username, cookieOptions);
            document.location.href = "https://app.mendelgen.com/Dashboard";

            // this.token$.next(data.jwt);
            
            // localStorage.setItem("userId", data.user.id);
            // localStorage.setItem("userEmail", data.user.email);
            // localStorage.setItem("userName", data.user.username);
            // this.user$.next(data.user);
            // resultProc("success");
    
            // if(localStorage.getItem(THEME) && localStorage.getItem(THEME) === "bp3-dark")
            // {
            //   document.body.classList.toggle("bp3-dark");
            // }
          }).catch(error => {
            //console.log(error);
            // resultProc("failed");
            if (error.response && error.response.status === 400) {
                setShowError(true);
                setErrorMsg("Incorrect email or password. Please try again.");
            } else {
                setShowError(true);
                setErrorMsg("Incorrect email or password. Please try again.");
            }
          });
    }

    return (
        <Modal show={showModal}
            onHide={closeProc}
            dialogClassName="modal-60w"
            aria-labelledby="exampleModalLabel"
        >
            <Modal.Body>
                <div className="login_container">
                    <div className="modal-header">
                        <div className="container">
                            <div className="row">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e)=>{closeProc()}}>
                                    {/* <i class="fa fa-close"></i> */}
                                    <BsX/>
                                </button>
                            </div>
                            <div className="row padding_header" >
                                <h5 className="modal-title" id="exampleModalLabel">Welcome back!</h5>
                            </div>
                            <div className="row padding_header">
                                <p id="sub_title">Enter your details below</p>
                            </div>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validateOnMount={false}
                        validate={values => validation(values)}
                        onSubmit={(values, { setSubmitting }) => {
                            setShowError(false);
                            // login(values,resultProc);
                            loginFromNext(values)
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            }) => (
                        <form onSubmit={handleSubmit} className="modal_form">
                            <div className="modal-body">
                                <div className="form-group mail">
                                    <label>EMAIL</label>
                                    <input type="email" className="form-control"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        placeholder="myemail@gmail.com"/>
                                    <span className={(errors.email && touched.email) ? 'warn' : 'hide'}>{errors.email}</span>
                                </div>
                                <div className="form-group password">
                                    <div className="row">
                                        <label>PASSWORD</label>
                                        <a id = "stone" onClick={(e)=>{resetPassword()}}>Forgot Password</a>
                                    </div>
                                    <input type="password" className="form-control"
                                         name="password"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         value={values.password}
                                         placeholder="Password"/>
                                    <span className={(errors.password && touched.password) ? 'warn' : 'hide'}>{errors.password}</span>
                                    {showError ?
                                        (
                                            <div className="alert alert-danger" style={{marginTop:"5px"}}>
                                                {errorMsg}
                                            </div>
                                        ):null}
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Sign In</button>
                            </div>
                        </form>
                        )}
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>

    );
}
export default Login;
