export const validation = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 6) {
    errors.password = 'Password should be more then 6 character';
  }

  /*if (!values.username) {
    errors.username = 'Required';
  } else if (!/^[a-zA-Z0-9]+$/i.test(values.username.trim())) {
    errors.username = 'Must be only letters';
  }*/

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required';
  } else if (values.confirmPassword != values.password) {
    errors.confirmPassword = 'Confirm Password is incorrect';
  }

  return errors;
};

