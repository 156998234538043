export const validation = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 6) {
    errors.password = 'Password should be more then 6 character';
  }

  if (!values.username) {
    errors.username = 'Required';
  } else if (!/^[A-Za-z\s]+$/.test(values.username) || values.username.includes("select") || values.username.includes("from") || values.username.includes("where")) {
    errors.username = 'Must be only letters';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)||values.email.includes("select")||values.email.includes("from")||values.email.includes("where")
  ) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

