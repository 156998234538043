import React,{useState} from 'react';
import { BsX } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik';
import {useAuth} from "../../hook/useAuth"
import {validation} from './validate'
import { useToasts } from 'react-toast-notifications'
const ResetPassword=(props)=>{
    const {showModal,closeProc} = props;
    const [showError, setShowError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");

    const { addToast } = useToasts();
    const { forgetPassword } = useAuth();
    const resultProc = (result) =>{
        console.log(result);
        if(result.success)
        {
            closeProc();
            addToast(result.msg, { appearance: 'success' });
        }
        else
        {
            setShowError(true);
            setErrorMsg(result.msg);
        }
    }
    return (
        <Modal show={showModal} onHide={closeProc}
        dialogClassName="modal-60w"
        aria-labelledby="exampleModalLabel">
            <Modal.Body>
                <div className="reset_container">
                    <div className="modal-header">
                        <div className="container">
                            <div className="row">
                                <button type="button" onClick={(e)=>{closeProc()}} className="close" data-dismiss="modal" aria-label="Close">
                                    {/* <i class="fa fa-close"></i> */}
                                    <BsX/>
                                </button>
                            </div>
                            <div className="row padding_header">
                                <h5 className="modal-title" id="exampleModalLabel">Forgot your password?</h5>
                            </div>
                            <div className="row padding_header">
                                <p id="sub_title">We&apos;ll help you reset it.</p>
                            </div>
                        </div>                              
                    </div>
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validateOnMount={false}
                        validate={values => validation(values)}
                        onSubmit={(values, { setSubmitting }) => {
                            setShowError(false);
                            forgetPassword(values.email,resultProc);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            }) => (
                            <form onSubmit={handleSubmit}  className="modal_form">
                            <div className="modal-body">
                                <div className="form-group email">
                                    <label>EMAIL</label>
                                    <input type="email" className="form-control" 
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email} 
                                        placeholder="myemail@gmail.com"/>
                                <span className={(errors.email && touched.email) ? 'warn' : 'hide'}>{errors.email}</span>
                                </div>
                                {showError ?
                                        (
                                            <div className="alert alert-danger" style={{marginTop:"5px"}}>
                                                {errorMsg}
                                            </div>
                                ):null}
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary btn-email">Send Email</button>
                            </div>
                            </form>
                            )}
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>       
    )
}
export default ResetPassword;