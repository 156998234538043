import React,{useState} from 'react';
import { BsX } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik';
import {useAuth} from "../../hook/useAuth"
import { useToasts } from 'react-toast-notifications'
import {validation} from './validate'

const NewPassword = (props) => {
    const {showModal,closeProc,resetCode,successProc} = props;
    const [showError, setShowError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");
    const {resetPassword} = useAuth();
    const { addToast } = useToasts();
    const resultProc = (result) =>{
        console.log(result);
        if(result.success)
        {
            successProc();
            addToast(result.msg, { appearance: 'success' });
        }
        else
        {
            setShowError(true);
            setErrorMsg(result.msg);
        }
    }
    return (
        <Modal show={showModal} 
        onHide={closeProc}
            dialogClassName="modal-60w"
            aria-labelledby="exampleModalLabel"
        >
            <Modal.Body>
                <div className="new_pw_container">
                    <div className="modal-header">
                        <div className="container">
                            <div className="row">
                                <button type="button" className="close" 
                                    onClick={(e)=>{closeProc()}}
                                    data-dismiss="modal" aria-label="Close">
                                    {/* <i class="fa fa-close"></i> */}
                                    <BsX/>
                                </button>
                            </div>
                            <div className="row padding_header" >
                                <h5 className="modal-title" id="exampleModalLabel">Reset your password</h5>
                            </div>
                            <div className="row padding_header">
                                <p id="sub_title">Enter your new password below</p>
                            </div>
                        </div>                              
                    </div>
                    <Formik
                        initialValues={{
                            password: '',
                            confirmPassword: '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validateOnMount={false}
                        validate={values => validation(values)}
                        onSubmit={(values, { setSubmitting }) => {
                            setShowError(false);
                            resetPassword(values,resetCode,resultProc);
                            
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            }) => (                        
                            <form className="modal_form" onSubmit={handleSubmit} >
                                <div className="modal-body">                                
                                    <div className="form-group newPassword">
                                        <label>NEW PASSWORD</label>
                                        <input type="password" className="form-control" 
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}                                         
                                            placeholder="New Password" 
                                        />
                                        <span className={(errors.password && touched.password) ? 'warn' : 'hide'}>{errors.password}</span>
                                    </div>
                                    <div className="form-group confirmPassword">
                                        <label>CONFIRM PASSWORD</label>
                                        <input type="password" className="form-control"
                                            name="confirmPassword"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.confirmPassword}          
                                            placeholder="Confirm Password"/>
                                        <span className={(errors.confirmPassword && touched.confirmPassword) ? 'warn' : 'hide'}>{errors.confirmPassword}</span>
                                        {showError ?
                                            (
                                                <div className="alert alert-danger" style={{marginTop:"5px"}}>
                                                    {errorMsg}
                                                </div>
                                            ):null}
                                    </div>
                                    
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Reset</button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>         
            </Modal.Body>
        </Modal>
        
    );
}
export default NewPassword;