import React from 'react';
import Link from 'next/link';
import useWindowDimensions from './utils/ScreenSize';

const Footer = ({ isSlug }) => {
  const { width } = useWindowDimensions();
  return (
    <footer
      className={`footer ${isSlug ? 'footer__isSlug' : ''}`}
      style={{ marginLeft: width < 800 ? 0 : 260 }}
    >
      <div className={`footer__container ${isSlug ? 'footer__container--isSlug' : ''}`}>
        <div className="footer__cell">
          <span>&copy; 2023 Mendelgen. All rights reserved</span>
        </div>
        <div className="footer__cell footer__cell--2">
          <span>
            <Link passHref href="/docs">
              <a className="userGuide">User Guide</a>
            </Link>
          </span>
          <span>
            <Link passHref href="/terms">
              Terms of Service
            </Link>
          </span>
          <span>
            <Link passHref href="/privacy">
              <a className="privacy">Privacy Policy</a>
            </Link>
          </span>
          <span>
            <Link passHref href="/eula">
              EULA
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
