import React,{useState} from 'react';
import { BsX } from "react-icons/bs";
import Link from 'next/link'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik';
import {validation} from './validate'
import { useToasts } from 'react-toast-notifications'
import {useAuth} from "../../hook/useAuth";
import { generateFormData } from '../../utils/keHelper';
import {registerUrl, redirectUrl, cookieOptions} from '../../config/endPoints';
import axios from 'axios';

import Cookies from 'js-cookie';
import { useRouter } from 'next/router'


// var createHost = require("cross-domain-storage/host");

// if (typeof window !== "undefined") {
//     // Client-side-only code
//     createHost([
//         {
//           origin: redirectUrl,
//           allowedMethods: ["get", "set", "remove"],
//         },
//       ]);
//   }

const Signup = (props) => {
    const {showModal,closeProc,successProc} = props;
    const { addToast } = useToasts();
    const { registration } = useAuth();

    const router = useRouter();


    const resultProc = (result) =>{
        if(result == "success")
        {
            closeProc();
            addToast('Registration success', { appearance: 'success' });
            localStorage.removeItem("sequenceDataId");
            localStorage.removeItem("newSeq");
            localStorage.removeItem("wizardSeq");     
            //props.history.push("/plans");
            //props.history.push("/Editor");
            //router.push("/Editor");
            window.location.href = "https://app.mendelgen.com/plans";

        }
        else
        {
            closeProc();
            addToast('Registration failed', { appearance: 'error' });
        }
    }

    const signUpFromNext = (values) => {
        const formData = generateFormData(values);
        axios.post(registerUrl, formData)
          .then(({data}) => {
            Cookies.set("token", data.jwt, cookieOptions);
            Cookies.set("userId", data.user.id, cookieOptions);
            Cookies.set("userEmail", data.user.email, cookieOptions);
            Cookies.set("userName", data.user.username, cookieOptions);
            Cookies.set('isNewUser', true, cookieOptions);
            document.location.href = redirectUrl;
          })
          .catch(error => {
            console.log(error);
            resultProc("failed")
          });
    }

    return (<>
        <Modal show={showModal} onHide={closeProc} dialogClassName="modal-60w"
        aria-labelledby="exampleModalLabel">
            <Modal.Body >
                <div className="signup_container" >
                    <div className="modal-header" >
                        <div className="container">
                            <div className="row" >
                                <button type="button" onClick={(e)=>{closeProc()}} className="close" data-dismiss="modal" aria-label="Close">
                                    {/* <i class="fa fa-close"></i> */}
                                    <BsX/>
                                </button>
                            </div>
                            <div className="row padding_header">
                                <h5 className="modal-title" id="exampleModalLabel">Create your account</h5>
                            </div>
                            <div className="row padding_header">
                              
                            </div>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            username: '',
                            email: '',
                            password: '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validateOnMount={false}
                        validate={values => validation(values)}
                        onSubmit={(values, { setSubmitting }) => {
                            //console.log("sign up step1")
                            registration(values,resultProc);
                            signUpFromNext(values)
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            }) => (
                        <form onSubmit={handleSubmit} className="modal_form">
                            <div className="modal-body">

                                <div className="form-group mail">
                                    <label>EMAIL</label>
                                    <input type="email" className="form-control"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        placeholder="myemail@gmail.com"/>
                                    <span className={(errors.email && touched.email) ? 'warn' : 'hide'}>{errors.email}</span>
                                </div>
                                <div className="form-group name">
                                    <label>YOUR NAME</label>
                                    <input type="text" className="form-control"
                                        name="username"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.username}
                                        placeholder="Tony Stank"/>
                                    <span className={(errors.username && touched.username) ? 'warn' : 'hide'}>{errors.username}</span>
                                </div>
                                <div className="form-group password">
                                    <label>PASSWORD</label>
                                    <input type="password" className="form-control"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        placeholder="Password"/>
                                    <span className={(errors.password && touched.password) ? 'warn' : 'hide'}>{errors.password}</span>
                                </div>
                                <p className="lead term">
                                    By clicking the &quot;Sign Up&quot; button, you agree to Mendelgen&apos;s <Link href="/privacy" passHref>privacy policy</Link> and <Link href="/terms" passHref>terms of service</Link>.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Sign Up</button>
                            </div>
                        </form>
                        )}
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
    </>);
}
export default (Signup);
